<template>
    <!-- When in doubt, consult the Vuetify documentation for v-data-table with CRUD actions. Link below: -->
    <!-- https://vuetifyjs.com/en/components/data-tables/#crud-actions -->

    <v-layout v-resize="onResize">
        <v-data-table data-testid="znap-table"
            :headers="apiResult.headers"
            :items="apiResult.items"
            :items-per-page="50"
            :footer-props="footerProps"
            :page.sync="pagination.page"
            :sort-by.sync="sortBy"
            @pagination="updatePage"
            :item-key="getItemKey()"
            :show-select="readOnly ? false : true"
            :search="search"
            :loading="loading"
            loading-text="Carregando dados..."
            v-model="selected"
            class="elevation-1 text-no-wrap"
            :height="noData ? '' : windowSize.y - 64 - 157 - hasOptions"
            fixed-header
            style="width: 100%;"
        >
            <template v-slot:top>
                <v-toolbar flat class="rounded-lg">
                    <div class="d-flex align-start mr-4">
                        <v-icon x-large color="primary">
                            {{ tableIcon }}
                        </v-icon>
                    </div>
                    <div class="d-flex flex-column align-start">
                        <span class="text-h6 font-weight-regular">{{ tableName }}</span>
                        <!-- <div class="mb-2 d-flex align-center">
                            <v-icon small color="primary" class="mr-2">mdi-information-outline</v-icon>
                            <span class="text-caption">{{ tableDescription }}</span>
                        </div> -->
                    </div>

                    <v-spacer></v-spacer>

                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$vuetify.lang.t('$vuetify.searchLabel')"
                        hide-details
                        single-line
                        dense
                        clearable
                        class="mr-4"
                        style="width: 1%;"
                    />

                    <v-tooltip top v-if="filterConditions.length > 0">
                        <template v-slot:activator="{on,attrs}">
                                <v-btn
                                    color="secondary" dark
                                    v-on="on"
                                    v-bind="attrs"
                                    class="mb-2 mr-4 pa-0"
                                    min-width="48px"
                                    @click="clearFilters()"
                                >
                                    <v-icon>mdi-cancel</v-icon>
                                </v-btn>
                        </template>
                        <span>Limpar filtros</span>
                    </v-tooltip>

                    <v-tooltip top v-if="selected.length === 1 && !editOnly && createPermission">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary" dark
                                v-on="on"
                                class="mb-2 mr-4 pa-0"
                                min-width="48px"
                                @click="duplicateSelectedItem()"
                            >
                                <v-icon>mdi-content-duplicate</v-icon>
                            </v-btn>
                        </template>
                        <span>Duplicar item selecionado</span>
                    </v-tooltip>

                    <v-tooltip top v-if="selected.length === 1 && updatePermission">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary" dark
                                v-on="on"
                                class="mb-2 mr-4 pa-0"
                                min-width="48px"
                                @click="editSelectedItem()"
                            >
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar item selecionado</span>
                    </v-tooltip>

                    <v-tooltip top v-if="selected.length > 0 && !editOnly && deletePermission">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                color="secondary" dark
                                v-on="on"
                                class="mb-2 mr-4 pa-0"
                                min-width="48px"
                                @click="dialogMassDelete = true"
                            >
                                <v-icon>mdi-delete-sweep</v-icon>
                            </v-btn>
                        </template>
                        <span>Deletar itens selecionados</span>
                    </v-tooltip>

                    <v-dialog v-model="dialogMassDelete" max-width="600px">
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline text-center">Tem certeza de que deseja excluir estes itens?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="closeMassDelete" class="mr-4">Cancelar</v-btn>
                                <v-btn color="primary" @click="massDeleteItemConfirm" :loading="massDeleting">Confirmar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-tooltip top v-if="!readOnly && !editOnly && createPermission">
                        <template v-slot:activator="{ on }">
                            <v-btn data-testid="new-item-btn"
                                color="primary"
                                v-on="on"
                                class="mb-2 mr-4 pa-0"
                                min-width="48px"
                                @click="dialog = true"
                                :disabled="!apiResult.headers || !apiResult.headers.length"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Novo item</span>
                    </v-tooltip>

                    <v-dialog v-model="dialog" max-width="900px" v-if="!readOnly">
                        <v-card style="position: relative;">
                            <v-card-title class="light--text"
                                :style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
                                style="position: sticky; top: 0; width: 100%; z-index: 1000;"
                            >
                                <span ref="formTitle" class="headline">{{ formTitle }}</span>

                                <v-spacer></v-spacer>

                                <v-tooltip top>
                                    <template v-slot:activator="{on}">
                                        <v-btn v-on="on"
                                            class="pa-0 mr-2"
                                            @click="returnEditedItemToDefault()"
                                            min-width="48px"
                                            text dark
                                        >
                                            <v-icon color="light">mdi-restart</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Limpar campos</span>
                                </v-tooltip>

                                <v-tooltip top>
                                    <template v-slot:activator="{on}">
                                        <v-btn data-testid="znap-dialog-close-button"
                                            v-on="on"
                                            class="pa-0"
                                            @click="close"
                                            min-width="48px"
                                            text dark
                                        >
                                            <v-icon color="light">mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Fechar</span>
                                </v-tooltip>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <ZnapDialog
                                        :editedItem="editedItem"
                                        :editedIndex="editedIndex"
                                        :disabled="disabled"
                                        :options="options"
                                        :filterConditions="filterConditions"
                                    />
                                </v-container>
                            </v-card-text>
                            <div class="light" style="position: sticky; bottom: 0; width: 100%;">
                                <v-divider class="mx-4"></v-divider>
                                <v-card-actions class="px-6 py-6">
                                    <span class="text-caption primary--text ml-3">* Campos obrigatórios</span>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="close"
                                        class="mr-4"
                                    >
                                        Cancelar
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        @click="save"
                                        :loading="saving"
                                        :disabled="disabled"
                                    >
                                        Salvar
                                    </v-btn>
                                </v-card-actions>
                            </div>
                        </v-card>
                    </v-dialog>

                    <v-tooltip top>
                        <template v-slot:activator="{on,attrs}">
                            <v-btn
                                color="primary"
                                v-on="on"
                                v-bind="attrs"
                                class="mb-2 mr-4 pa-0"
                                min-width="48px"
                                @click="refresh()"
                                :disabled="hasRequiredFilters()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Atualizar</span>
                    </v-tooltip>

                    <v-tooltip top v-if="!readOnly">
                        <template v-slot:activator="{on,attrs}">
                            <v-btn
                                :class="{ 'primary': !unsavedChanges, 'secondary': unsavedChanges }"
                                v-on="on"
                                v-bind="attrs"
                                class="mb-2 mr-4 pa-0"
                                min-width="48px"
                                @click="massSave(getItemKey())"
                                :disabled="!unsavedChanges"
                            >
                                <v-icon> {{ unsavedChanges ? 'mdi-content-save-alert' : 'mdi-content-save' }}</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            {{ unsavedChanges ? 'Salvar edições' : 'Nenhum dado foi alterado'}}
                        </span>
                    </v-tooltip>

                    <v-tooltip top v-if="!readOnly && crudPermission">
                        <template v-slot:activator="{on,attrs}">
                            <v-btn
                                color="primary"
                                v-on="on"
                                v-bind="attrs"
                                class="mb-2 mr-4 pa-0"
                                @click="importSheet"
                                :loading="importing"
                                min-width="48px"
                                :disabled="!apiResult.headers || !apiResult.headers.length"
                            >
                                <v-icon>mdi-file-excel</v-icon>
                            </v-btn>
                            <input
                                ref="uploader"
                                class="d-none"
                                type="file"
                                @change="onFileChanged"
                            >
                        </template>
                        <span>Importar Excel</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{on,attrs}">
                            <v-btn
                                color="primary"
                                v-on="on"
                                v-bind="attrs"
                                class="mb-2 mr-4 pa-0"
                                @click="exportSheet"
                                :loading="exporting"
                                min-width="48px"
                                :disabled="noData"
                            >
                                <v-icon>mdi-microsoft-excel</v-icon>
                            </v-btn>
                        </template>
                        <span>Exportar Excel</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn data-testid="znap-table-close-button"
                                color="primary" dark
                                v-on="on"
                                class="mb-2 pa-0"
                                min-width="48px"
                                @click="closeTable()"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>

                    <v-dialog v-model="dialogCloseTable" max-width="600px">
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline text-center">Você possui edições não salvas. Tem certeza de que deseja fechar a tabela?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialogCloseTable = false" class="mr-4">Cancelar</v-btn>
                                <v-btn color="primary" @click="$router.push('/')">Confirmar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialogDelete" max-width="600px">
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline text-center">Tem certeza de que deseja excluir este item?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="closeDelete" class="mr-4">Cancelar</v-btn>
                                <v-btn color="primary" @click="deleteItemConfirm" :loading="deleting">Confirmar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialogEditItem" max-width="600px">
                        <v-card>
                            <div class="d-flex justify-center">
                                <v-card-title class="headline text-center">Você possui edições não salvas. Tem certeza de que deseja prosseguir?</v-card-title>
                            </div>
                            <v-card-actions class="px-6 pb-6">
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="dialogEditItem = false, close()" class="mr-4">Cancelar</v-btn>
                                <v-btn color="primary" @click="dialog = true, dialogEditItem = false">Confirmar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>

                <v-divider class="mx-4 mb-2"></v-divider>

                <slot name="filters"></slot>
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:header.data-table-select="{ on, props }">
                <v-simple-checkbox v-if="!noData"
                    color="secondary"
                    v-bind="props"
                    v-on="on"
                    :ripple="false"
                ></v-simple-checkbox>
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item="{ item, index, select, isSelected }">
                <tr :class="{ 'edited-row': setEditedRow(item), 'selected-row': setSelectedRow(item)}"
                    class="table-row"
                >
                    <td class="d-block d-sm-table-cell" v-if="!readOnly">
                        <v-simple-checkbox
                            :key="item[getItemKey()]"
                            color="secondary"
                            :value="isSelected"
                            @input="select($event)"
                            :ripple="false"
                        ></v-simple-checkbox>
                    </td>

                    <template v-for="(header, headerIndex) in apiResult.headers">
                        <td :key="headerIndex"
                            :class="{ 'actions-column text-end': header.value === 'actions'}"
                            class="d-block d-sm-table-cell"
                        >                        
                            <template v-if="header.value === 'actions' && !readOnly"
                                class="d-flex justify-center align-center"
                            >
                                <v-tooltip top v-if="updatePermission">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon
                                            v-on="on"
                                            @click="editItem(item)"
                                        >
                                            <v-icon>
                                                mdi-pencil
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Editar item</span>
                                </v-tooltip>

                                <v-tooltip top v-if="!editOnly && deletePermission">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon
                                            v-on="on"
                                            @click="deleteItem(item)"
                                        >
                                            <v-icon>
                                                mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Deletar item</span>
                                </v-tooltip>
                            </template>

                            <div v-if="header.type === 'INT' && header.columnType === 'check'"
                                style="width: 100%;" class="d-flex justify-center align-center"
                            >
                                <v-simple-checkbox
                                    color="primary"
                                    :value="formatCheckboxValue(item[header.value])"
                                    @input="setCheckboxValue($event, item, header.value)"
                                    :ripple="false"
                                    :disabled="!updatePermission"
                                >
                                </v-simple-checkbox>
                            </div>

                            <!-- Open ZnapStylesDialog -->
                            <div v-if="header.value === 'css'"
                                @click="openStylesDialog(item, index)"
                                style="cursor: pointer; width: 100%; height: 100%;"
                                class="d-flex align-center"
                            >
                                <span>{{ item[header.value] }}</span>
                            </div>

                            <!-- Open ZnapFormulaDialog -->
                            <div v-if="header.value === 'formula'"
                                @click="dialogFormula = true, editedRowFormula = item"
                                style="cursor: pointer; width: 100%; height: 100%;"
                                class="d-flex align-center"
                            >
                                <span style="display: inline-block; max-width: 500px; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;">
                                    {{ item[header.value] }}
                                </span>
                            </div>

                            <v-edit-dialog v-if="updatePermission && header.value !== sortBy[0] && !header.hide && !header.notEditable && !header.img && header.value !== 'actions' && header.columnType !== 'check' && header.value !== 'css' && header.value !== 'formula'"
                                :return-value.sync="item[header.value]"
                                large
                                cancel-text="Cancelar"
                                save-text="Alterar"
                                @close="close"
                                @cancel="cancelSelectedOption(item, header.columnId)"
                                @open="backupSelect(index, header.columnId)"
                                @save="itemHasBeenEdited(item)"
                            >
                                <template v-if="!header.groupInfo">
                                    <span style="display: inline-block; max-width: 500px; white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;">
                                        {{ setEditDialogContent(header, item) }}
                                    </span>
                                </template>

                                <template v-else-if="header.groupInfo">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on }">
                                            <v-btn small text color="primary"
                                                v-on="on"
                                            >
                                                <v-icon small color="primary" class="mr-2">mdi-eye</v-icon>
                                                Visualizar
                                            </v-btn>
                                        </template>
                                        <v-list min-width="250px" max-height="400px">
                                            <v-list-item class="white">
                                                <v-btn color="primary" small
                                                    @click="navigateTo(header, item)"
                                                >
                                                    <v-icon small class="mr-2">mdi-plus</v-icon>
                                                    Adicionar
                                                </v-btn>

                                                <v-spacer></v-spacer>

                                                <v-btn icon color="primary">
                                                    <v-icon color="primary">mdi-close</v-icon>
                                                </v-btn>
                                            </v-list-item>

                                            <v-divider class="mt-2"></v-divider>
                                            
                                            <v-list-item v-for="groupInfoItem in getGroupInfoItems(header, item)" :key="groupInfoItem.id"
                                                class="white"
                                            >
                                                <v-list-item-title>{{ groupInfoItem.text }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </template>

                                <template v-slot:input>
                                    <v-text-field v-if="!header.options && (header.type === 'VARCHAR' || header.type === 'TEXT')"
                                        v-model="item[header.value]"
                                        :label="header.text"
                                        type="text"
                                        single-line
                                        :counter="header.length"
                                        :maxlength="header.length"
                                    />

                                    <v-text-field v-if="!header.options && header.type === 'INT' && header.columnType !== 'check'"
                                        v-model.number="item[header.value]"
                                        :label="header.text"
                                        type="number"
                                        single-line
                                        :counter="header.length"
                                        :maxlength="header.length"
                                    />

                                    <v-text-field v-if="!header.options && header.type === 'DECIMAL'"
                                        v-model.number="item[header.value]"
                                        @change="formatFloat(item[header.value])"
                                        :label="header.text"
                                        type="number"
                                        single-line
                                        :counter="header.length"
                                        :maxlength="header.length"
                                    />

                                    <v-autocomplete v-if="header.options && header.columnType !== 'radio'"
                                        :label="header.text"
                                        v-model="item[header.value]"
                                        :items="getOptions(header)"
                                        item-value="text"
                                        return-object
                                        :clearable="!isRequired(header)"
                                        @change="setSelectedOption(item, header.columnId, header.value, $event)"
                                    />

                                    <v-select v-if="header.options && header.columnType === 'radio'"
                                        :label="header.text"
                                        :items="header.options"
                                        item-value="text"
                                        v-model="item[header.value]"
                                        :clearable="!isRequired(header)"
                                    />

                                    <v-menu v-if="header.type === 'DATE' || header.type === 'DATETIME'"
                                        :ref="'menu' + index"
                                        v-model="datepicker[index]"
                                        :close-on-content-click="false"
                                        :return-value.sync="item[header.value]"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                :value="formatDate(item[header.value])"
                                                :label="header.text"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-on="on"
                                                clearable
                                                @click:clear="setSelectedOption(item, header.columnId, header.value, $event)"
                                            ></v-text-field>
                                        </template>

                                        <v-date-picker
                                            v-model="item[header.value]"
                                            no-title
                                            scrollable
                                            color="primary"
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="saveSelectedDate(item[header.value], index)">
                                                Confirmar
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </template>
                            </v-edit-dialog>

                            <div v-else-if="header.img" class="d-flex justify-center">
                                <v-avatar v-if="item[header.value]" class="my-1" size="54">
                                    <v-img :src="item[header.value]">
                                        <template v-slot:placeholder>
                                            <v-skeleton-loader
                                                type="avatar"
                                            ></v-skeleton-loader>
                                        </template>
                                    </v-img>
                                </v-avatar>

                                <v-avatar v-else class="my-1" size="54">
                                    <v-sheet>
                                        <v-icon color="primary" size="62">mdi-account-circle</v-icon>
                                    </v-sheet>
                                </v-avatar>
                            </div>

                            <div v-else-if="header.columnType !== 'check' && header.align === 'center' && header.value !== 'css' && header.value !== 'formula'" class="d-flex justify-center">
                                <span>{{ item[header.value] }}</span>
                            </div>

                            <div v-else-if="header.columnType !== 'check' && header.value !== 'css' && header.value !== 'formula'"
                                class="d-flex justify-start"
                            >
                                <span>{{ item[header.value] }}</span>
                            </div>
                        </td>
                    </template> 
                </tr>
            </template>

            <template v-slot:no-data>
                <span data-testid="znap-table-no-data-message"
                    class="text-h6 primary--text"
                >
                    {{ noDataMessage }}
                </span>
            </template>
        </v-data-table>

        <ZnapStylesDialog v-if="dialogStyles"
            :dialogStyles="dialogStyles"
            :editedRowStyles="editedRowStyles"
            @closeDialogStyles="dialogStyles = false"
            @saveEditedRowStyle="saveEditedRowStyle($event)"
        />

        <v-dialog v-model="dialogFormula" max-width="1000px" v-if="editedRowFormula">
            <ZnapFormulaDialog
                :editedRowFormula="editedRowFormula"
                :filterConditions="filterConditions"
                @closeDialogFormula="dialogFormula = false, editedRowFormula = null"
                @setEditedRowFormula="setEditedRowFormula($event)"
            />
        </v-dialog>
    </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import ZnapDialog from '@/components/znap/ZnapDialog'
import ZnapStylesDialog from '@/components/znap/ZnapStylesDialog'
import ZnapFormulaDialog from '@/components/znap/ZnapFormulaDialog'
import lodash from 'lodash'
import moment from 'moment'
import configs from '@/configs'
import getModulePort from '@/helpers/getModulePort'

export default {
    name: 'ZnapTable',

    components: { ZnapDialog, ZnapStylesDialog, ZnapFormulaDialog },

    props: {
        endpoint: { type: Array, required: true },
        // tableName: { type: String, required: true },
        // tableIcon: { type: String, required: true, default: 'mdi-view-list' },
        tableDescription: { type: String, required: false },
        tableRows: { type: Number, required: true, default: 1000 },
        options: { type: Array, required: true },
        noData: { type: Boolean },
        noDataMessage: { type: String },
        filterConditions: { type: Array },
        checkboxFilters: { type: Array },
        loadTable: { type: Boolean },
        defaultFieldsComplement: { type: Array, default: () => [] },
        // payloadComplement: { type: Object },
        readOnly: { type: Boolean, required: false, default: false },
        editOnly: { type: Boolean, required: false, default: false }
    },

    data() {
        return {
            apiResult: {
                items: [],
                headers: [],
                columns: [],
            },

            footerProps: {
                itemsPerPageOptions: [5, 10, 15, 25, 50, 100, 500, 1000, 5000, -1],
                itemsPerPageAllText: 'TODOS'
            },

            sortBy: [],
    
            dialog: false,
            dialogDelete: false,
            dialogEditItem: false,
            dialogMassDelete: false,
            dialogCloseTable: false,

            dialogStyles: false,
            editedRowStyles: null,

            dialogFormula: false,
            editedRowFormula: null,

            datepicker: [],
            editedIndex: -1,
            search: '',
            selected: [],
            saving: false,
            deleting: false,
            massDeleting: false,
            importing: false,
            exporting: false,
            backupSelectValue: null,
            windowSize: { x: 0, y: 0 },
            znapFiltersContainerHeight: 0,

            pagination: {},
            backupPage: 1,
    
            loading: false,
    
            // Dialogs
            editedItem: [],
            editedItemsId: [],
            defaultItem: [],
    
            // Unsaved changes
            unsavedChanges: false,
            itemsInitialState: [],
    
            checkbox: false,

            crudPermission: true,
            createPermission: true,
            updatePermission: true,
            deletePermission: true,
        }
    },

    computed: {
        ...mapGetters('auth', ['getHash', 'getMenu']),

        tableName() {
            const tableName = this.getTableInfo().tableName

            return tableName ? tableName : 'Erro ao listar a tabela'
        },

        tableIcon() {
            const tableIcon = this.getTableInfo().tableIcon

            return tableIcon ? tableIcon : 'mdi-view-list'
        },

        hasOptions() {
            let height = 0

            if (this.znapFiltersContainerHeight) {
                height = this.znapFiltersContainerHeight
            }

            return height
        },

        formTitle() {
            return this.editedIndex <= 0 ? 'Novo item' : 'Editar item'
        },

        disabled() {
            let validation = []

            this.editedItem.forEach((item) => {
                if (item.rules.includes('required') && !item.hide) {
                    if (item.value === null || item.value === '') {
                        validation.push('disabled')
                    }
                }
            })

            return validation.includes('disabled') ? true : false
        },
    },

    watch: {
        dialog (val) {
            val || this.close()
        },

        dialogDelete (val) {
            val || this.closeDelete()
        },

        dialogMassDelete (val) {
            val || this.closeMassDelete()
        },

        loadTable (val) {
            if (val) {
                this.load()
            }
        }
    },

    created() {
        let routePath = this.$route.path
        // if (this.$route.path === '/users') {
        //     routePath = '/user'
        // }

        const permissions = this.$getPermissions(routePath)
        for (let key in permissions) {
            this[key] = permissions[key]
        }

        this.load()
    },

    methods: {
        getTableInfo() {
            let tableInfo = {
                tableName: '',
                tableIcon: '',
            }

            for (let i = 0; i < this.getMenu.length; i++) {
                let submenu = this.getMenu[i].menus.find(m => m.frontend_route_path === this.$route.path)
                if (submenu) {
                    tableInfo.tableIcon = this.getMenu[i].icon
                    tableInfo.tableName = submenu.label
                }
            }

            return tableInfo
        },

        navigateTo(header, item) {
            let groupInfoPath = this.apiResult.groupInfo[header.groupInfo].path
            let route = groupInfoPath.route
            let systemModule = groupInfoPath.module.toLowerCase()

            let url = window.location.href
            let path = null
            let port = getModulePort(systemModule)

            if (url.includes(':80')) {
                path = `http://${configs.system}.${systemModule}.znaptech:${port}/?token=${this.getHash}&route=${route}&id_group=${item.id_group}`
            } else {
                path = `https://${configs.system}${configs.env === 'homol' ? '.homol' : ''}.${systemModule}.znaptech.com/?token=${this.getHash}&route=${route}&id_group=${item.id_group}`
            }

            return window.open(path)
        },

        setEditDialogContent(header, item) {
            if (header.type === 'DATE' || header.type === 'DATETIME') {
                return this.formatDate(item[header.value])
            } else if (header.type === 'DECIMAL') {
                return this.formatFloat(item[header.value])
            } else if (header.value === 'cpf' || header.value === 'cnpj') {
                return this.formatCpfCnpj(item[header.value])
            } else {
                return item[header.value] || ''
            }
        },

        isRequired(header) {
            if (header.rules) {
                return header.rules.includes('required')
            } else {
                return false
            }
        },

        openStylesDialog(item, index) {
            this.dialogStyles = true
            this.editedRowStyles = {
                id: item.id_account_group,
                css: item.css,
                row: index 
            }
        },

        setEditedRowStyle(event) {
            let index = this.editedRowStyles.row
            let id = this.editedRowStyles.id

            this.apiResult.items[index].css = event
            this.editedItemsId.push(id)
            this.unsavedChanges = true
            
            this.editedRowStyles = null
            this.dialogStyles = false
        },

        async saveEditedRowStyle(payload) {
            try {
                const res = await this.$http.put('https://api.account.znaptech.com/account-group/edit-css', { ...payload })
                if (res) {
                    this.setEditedRowStyle(payload.items[0].css)
                    this.dialogStyles = false
                    this.saving = false
                }
            } catch (err) {
                this.$fnError(err)
                this.saving = false
            }

            this.dialogStyles = false
        },

        setEditedRowFormula(event) {
            let index = this.apiResult.items.findIndex(item => item.id_account_group_calc === this.editedRowFormula.id_account_group_calc)
            let idAccountGroupCalc = this.apiResult.items.find(item => item.id_account_group_calc === this.editedRowFormula.id_account_group_calc).id_account_group_calc
            
            if (this.apiResult.items[index].formula !== event) {
                this.apiResult.items[index].formula = event.formula
                this.apiResult.items[index].variable = event.variable
                this.editedItemsId.push(idAccountGroupCalc)
                this.unsavedChanges = true
            }
            
            this.editedRowFormula = null
            this.dialogFormula = false
        },

        closeDatepicker(index) {
            this.datepicker[index] = false
        },

        clearFilters() {
            let requiredOption = this.options.find(option => option.required)
            if (requiredOption) this.$emit('setNoDataMessage', 'Selecione uma opção do filtro de ' + requiredOption.label + ' para prosseguir')
            if (requiredOption) this.selected = []
            this.$emit('clearFilters', [])
        },

        refresh() {
            this.backupPage = this.pagination.page
            this.load()
        },

        updatePage (pagination) {
            if (pagination.page !== this.pagination.page) {
                pagination.page = this.pagination.page
            }
            this.pagination = pagination
        },
        
        load() {
            this.$emit('setLoadTable', false)

            let hasRequiredFilters = this.hasRequiredFilters()
            let requiredFilterIsEmpty = this.requiredFilterIsEmpty()
            let requiredFilter = this.options.find(option => option.required)

            if (hasRequiredFilters && requiredFilterIsEmpty) {
                this.populateRequiredOptions()
                this.$emit('setNoData', true)
                this.$emit('setNoDataMessage', 'Selecione uma opção do filtro de ' + requiredFilter.label + ' para prosseguir')
            } else if (hasRequiredFilters && !requiredFilterIsEmpty) {
                this.clearTableData()
                this.$emit('setNoData', true)
                this.$emit('setNoDataMessage', 'Selecione uma opção do filtro de ' + requiredFilter.label + ' para prosseguir')
                this.$emit('clearNotRequiredOptionsItems')
            } else {
                this.loading = true
                this.clearTableData()
                this.populateNotRequiredOptions()
                
                let foundOption = this.options.find(option => option.payloadComplement) || {}
                let payload = this.setPayload(foundOption.payloadComplement, this.endpoint[2])

                this.$http.post(this.endpoint[0] + this.endpoint[1] + '/list', payload)
                .then(res => {
                    this.apiResult = {
                        columns: res.data.columns,
                        headers: res.data.headers,
                        items: res.data.rows,
                        groupInfo: res.data.groupInfo || null,
                    }

                    this.apiResult.items = this.setItems(res.data.rows, res.data.columns)

                    let _headers = this.setHeaders(res.data.headers, res.data.columns)
    
                    // EditedItem is used to create table dialogs
                    let defaultFields = this.setDefaultFields(_headers)
                    this.defaultItem = lodash.cloneDeep(defaultFields)
                    this.editedItem = defaultFields
    
                    // Actions
                    _headers.push({ text: '', value: 'actions', sortable: false })
                    this.apiResult.headers = _headers
    
                    // Unsaved changes
                    this.itemsInitialState = lodash.cloneDeep(this.apiResult.items)
                    
                    if (res.data.rows.length === 0) {
                        this.$emit('setNoDataMessage', 'Não há dados disponíveis')
                    } else {
                        this.$emit('setNoData', false)
                    }

                    this.pagination.page = this.backupPage
                    return this.loading = false
                })
                .catch((err)=>{
                    this.$fnError(err)
                })
            }
        },

        populateRequiredOptions() {
            this.options.forEach((option, i) => {
                if (option.required) {
                    option.items = []

                    let payload = {
                        filter: {
                            conditions: []
                        }
                    }

                    if (option.filter) {
                        payload.filter = {
                            ...option.filter
                        }
                    } else if (option.conditions) {
                        payload.filter.conditions = option.conditions
                    }

                    this.$http.post(option.endpoint[0] + option.endpoint[1] + '/list-options', { ...payload } )
                        .then((res) => {
                            res.data.rows.forEach(row => {
                                return this.options[i].items.push({ id: row.id, text: row.text })
                            })
                        }).catch((err) => {
                            this.$fnError(err)
                        })
                }    
            })
        },

        populateNotRequiredOptions() {
            this.options.forEach((option, i) => {
                if (option.is === "ComboBox") {
                    let populate = true

                    this.filterConditions.forEach(condition => {
                        if (condition.column === option.column) {
                            populate = false
                        }
                    })

                    let payload = {
                        filter: {
                            conditions: []
                        }
                    }

                    if (option.filterable) {
                        this.filterConditions.forEach(condition => {
                            if (condition.required) {
                                payload.filter.conditions.push(condition)
                            }
                        })
                    }

                    if (!option.required && populate) {
                        option.items = []

                        if (option.filter) {
                            payload.filter = {
                                ...option.filter
                            }
                        } else if (option.conditions) {
                            payload.filter.conditions.push(...option.conditions)
                        }

                        this.$http.post(option.endpoint[0] + option.endpoint[1] + '/list-options', { ...payload } )
                            .then((res) => {
                                return this.options[i].items = res.data.rows
                            }).catch((err) => {
                                this.$fnError(err)
                            })
                    }
                }
            })
        },

        hasRequiredFilters() {
            let requiredFilters = null
            let hasRequiredFilters = false

            this.options.forEach(option => {
                if (option.required) requiredFilters = true
            })

            if (requiredFilters && this.filterConditions.length === 0) {
                hasRequiredFilters = true
            }

            return hasRequiredFilters
        },

        requiredFilterIsEmpty() {
            let requiredFilterIsEmpty = false
            this.options.forEach(option => {
                if (option.required && !option.items.length) {
                    requiredFilterIsEmpty = true
                } 
            })

            return requiredFilterIsEmpty
        },

        setPayload(payloadComplement = {}, endpointFilter = {}) {
            let conditions = []
            if (endpointFilter.conditions) {
                conditions = [ ...this.filterConditions, ...endpointFilter.conditions ]
            } else {
                conditions = [ ...this.filterConditions ]
            }

            let checkboxConditions = {}
            let checkboxInConditions = []
            if (this.checkboxFilters) {
                this.checkboxFilters.forEach(f => {
                    let option = this.options.find(o => o.is === "Checkbox" && o.column === f.column)
                    
                    if (option.inConditions && f.value) {
                        checkboxInConditions.push({
                            AndOr: 'AND',
                            column: 'tag',
                            not: true,
                            operator: 'CONTAINS',
                            value: '_REPORT'
                        })
                    } else {
                        checkboxConditions[f.column] = f.value
                    }
                })
            }

            return {
                ...payloadComplement,
                ...checkboxConditions,
                filter: {
                    tableRows: this.tableRows,
                    conditions: [
                        ...conditions,
                        ...checkboxInConditions
                    ]
                }
            }
        },

        setItems(items, columns) {
            columns.forEach(column => {
                if (column.columnType === 'DATE' || column.columnType === 'DATETIME') {
                    items.forEach(item => {
                        if (item[column.columnAlias]) {
                            item[column.columnAlias] = moment.utc(item[column.columnAlias]).format("YYYY-MM-DD")
                        }
                    })
                }
            })
            
            return items
        },

        setHeaders(headers, columns) {
            let _headers = []

            headers.forEach((header, i) => {
                let width = ''
                let type = ''
                let rules = []
                let hide = false
                let key = ''
                let length = null
                let index = columns.map(column => column.columnAlias).indexOf(header.value)

                if (index === -1) {
                    index = i
                }

                if (header.width !== undefined ) {
                    width = header.width
                } else {
                    width = "1%"
                }

                if (header.type !== undefined) {
                    type = header.type
                } else if (header.groupInfo) {
                    type = 'groupInfo'
                } else {
                    if (header.file) {
                        type = 'IMG'
                    } else {
                        if (columns[index]) {
                            type = columns[index].columnType
                        }
                    }
                }

                if (header.rules !== undefined) {
                    rules.push(header.rules)
                } else {
                    let columnIdIndex = index

                    if (header.columnId) {
                        columnIdIndex = columns.map(column => column.columnAlias).indexOf(header.columnId)
                    }

                    if (columns[columnIdIndex]) {
                        if (columns[columnIdIndex].nullColumn === 'NO') {
                            rules.push('required')
                        }
                    }
                }

                // if header hide is true table column will show but will not appear at edit dialog
                if (header.hide !== undefined) {
                    hide = header.hide
                } else {
                    if (columns[index]) {
                        if (columns[index].key === 'MUL' || columns[index].key === 'PRI') {
                            hide = true
                            key = columns[index].key
                        }
                    }
                }

                if (header.length !== undefined) {
                    length = header.length
                } else {
                    if (columns[index]) {
                        length = columns[index].columnLength
                    }
                }
                
                if (!header.hideColumn) {
                    _headers.push({ ...header, width, type, rules, hide, key, length })
                }
            })

            return _headers
        },

        setDefaultFields(_headers) {
            let defaultFields = []

            _headers.forEach(header => {
                defaultFields.push({
                    name: header.value,
                    label: header.text,
                    value: header.columnType === 'check' ? 0 : null,
                    type: header.type,
                    rules: header.rules,
                    hide: header.hide,
                    key: header.key,
                    options: header.options,
                    columnId: header.columnId,
                    length: header.length,
                    columnType: header.columnType
                })
            })

            this.defaultFieldsComplement.forEach(complement => {
                if (complement.create) defaultFields.splice(complement.position, 0, complement)
            })

            return defaultFields
        },

        returnEditedItemToDefault() {
            this.editedItem = lodash.cloneDeep(this.defaultItem)
        },

        setEditedItem(item) {
            this.defaultItem.forEach((defaultItem, index) => {
                if (defaultItem.edit !== false) {
                    if (defaultItem.name in item) {
                        if (item[defaultItem.name]) {
                            this.editedItem[index].value = item[defaultItem.name]
                        } else {
                            if (defaultItem.columnType === 'check') {
                                this.editedItem[index].value = 0
                            } else {
                                this.editedItem[index].value = null
                            }
                        }
                    }
                }
            })

            // remove defaultFieldsComplement if not editable
            this.editedItem.forEach((item, index) => {
                if (item.edit === false) {
                    this.editedItem.splice(index, 1)
                }
            })
        },

        clearTableData() {
            this.apiResult.items = []
            this.apiResult.headers = []
            this.editedItem = []
            this.defaultItem = []
            this.unsavedChanges = false
            this.editedItemsId = []

            this.$emit('setNoData', true)
        },

        clearOptions() {
            this.loading = true

            let requiredItems = []
            this.$emit('setFilterConditions', [])

            this.options.forEach(option => {
                if (option.required) {
                    requiredItems.push(option)
                }
            })

            if (requiredItems.length > 0) {
                this.options.forEach(option => {
                    if (!option.required) {
                        option.items = []
                    } else {
                        this.$emit('setNoDataMessage', 'Selecione uma opção do filtro de ' + option.label + ' para prosseguir')
                    }
                })
    
                this.clearTableData()
            }
            
            this.loading = false
        },

        closeTable() {
            if (!this.unsavedChanges) {
                this.$router.push('/')
            } else {
                this.dialogCloseTable = true
            }
        },

        setEditedRow(item) {
            let editedRow = this.editedItemsId.includes(item[this.getItemKey()])
            return editedRow
        },

        setSelectedRow(item) {
            return this.selected.find(i => i === item)
        },

        onResize () {
            let znapFiltersContainerHeight = this.$parent.$refs.znapFilters?.$el.clientHeight
            if (znapFiltersContainerHeight) this.znapFiltersContainerHeight = znapFiltersContainerHeight
            this.windowSize = { x: window.innerWidth, y: window.innerHeight };
        },

        saveSelectedDate(date, index) {
            this.$refs['menu' + index][0].save(date)
        },

        formatDate(date) {
            if (date === undefined || date === null) {
                return ''
            } else {
                return this.$options.filters.dateFilter(date)
            }
        },

        formatFloat(float) {
            if (float === undefined || float === null) {
                return ''
            } else {
                return this.$options.filters.floatFilter(float)
            }
        },

        formatCpfCnpj(cpfCnpj) {
            if (cpfCnpj === undefined || cpfCnpj === null) {
                return ''
            } else {
                return this.$options.filters.cpfCnpjFilter(cpfCnpj)
            }
        },

        formatCheckboxValue(check) {
            let value = null
            if (check === 1) value = true
            if (check === null || check === 0) value = false
            return value
        },

        setCheckboxValue(e, item, headerValue) {
            if (e) {
                this.apiResult.items.forEach(tableItem => {
                    if (tableItem[this.getItemKey()] === item[this.getItemKey()]) {
                        tableItem[headerValue] = 1
                        this.itemHasBeenEdited(item)
                    }
                })
            } else {
                this.apiResult.items.forEach(tableItem => {
                    if (tableItem[this.getItemKey()] === item[this.getItemKey()]) {
                        tableItem[headerValue] = 0
                        this.itemHasBeenEdited(item)
                    }
                })
            }
        },

        getOptions(header) {
            let option = this.options.find(option => option.column === header.columnId)
            return option.items
        },

        getGroupInfoItems(header, item) {
            let groupInfo = this.apiResult.groupInfo[header.groupInfo].groups
            let found = groupInfo.find(g => g.value === item[g.columnId])

            return found ? found.items : []
        },

        itemHasBeenEdited(item) {
            let field = this.getItemKey()
            let id = item[field]

            let _item = this.itemsInitialState.find(_item => {
                return _item[field] === item[field]
            })

            if (lodash.isEqual(_item, item)) {
                this.editedItemsId.forEach((editedItemId, index) => {
                    if (editedItemId === id) {
                        this.editedItemsId.splice(index, 1)
                    }
                })

                this.setUnsavedChanges()
            } else {
                if (!this.editedItemsId.includes(id)) this.editedItemsId.push(id)

                this.setUnsavedChanges()
            }
        },

        setUnsavedChanges() {
            if (this.editedItemsId.length > 0) {
                this.unsavedChanges = true
            } else {
                this.unsavedChanges = false
            }
        },

        backupSelect(index, columnId) {
            if (columnId) {
                this.backupSelectValue = this.apiResult.items[index][columnId]
            }
        },

        getItemKey() {
            return 'id_' + this.endpoint[1].replaceAll('-', '_')
        },

        setSelectedOption(item, columnId, name, e) {
            if (e) {
                this.apiResult.items.forEach(tableItem => {
                    if (tableItem[this.getItemKey()] === item[this.getItemKey()]) {
                        tableItem[columnId] = e.id
                        tableItem[name] = e.text
                    }
                })
            } else {
                this.apiResult.items.forEach(tableItem => {
                    if (tableItem[this.getItemKey()] === item[this.getItemKey()]) {
                        tableItem[columnId] = null
                    }
                })
            }
        },

        cancelSelectedOption(item, columnId) {
            this.apiResult.items.forEach(tableItem => {
                if (tableItem[this.getItemKey()] === item[this.getItemKey()]) {
                    tableItem[columnId] = this.backupSelectValue
                }
            })
        },

        duplicateSelectedItem() {
            let item = this.selected[0]
            this.editedIndex = 0
            this.setEditedItem(item)

            if (this.unsavedChanges) {
                this.dialogEditItem = true
            } else {
                this.dialog = true
            }
        },

        editSelectedItem() {
            let item = this.selected[0]
            this.editedIndex = item[this.getItemKey()]
            this.setEditedItem(item)

            if (this.unsavedChanges) {
                this.dialogEditItem = true
            } else {
                this.dialog = true
            }
        },

        editItem(item) {
            this.editedIndex = item[this.getItemKey()]
            this.setEditedItem(item)

            if (this.unsavedChanges) {
                this.dialogEditItem = true
            } else {
                this.dialog = true
            }
        },

        deleteItem (item) {
            this.editedIndex = item[this.getItemKey()]
            this.dialogDelete = true
        },

        async deleteItemConfirm () {
            this.deleting = true
            try {
                const res = await this.$http.delete(this.endpoint[0] + this.endpoint[1] + `/delete/${this.editedIndex}`, {})
                if (res) {
                    if (this.selected.length > 0) {
                        this.selected = []
                    }

                    this.$toast.success(res.data.msg)
                }
            } catch (err) {
                this.$fnError(err)
            }

            this.closeDelete()
            this.deleting = false
            this.load()
        },

        massDeleteItem () {
            this.dialogMassDelete = true
        },

        async massDeleteItemConfirm() {
            this.massDeleting = true

            let payload = {
                delete: { items: [] },
            }

            payload.delete.items = this.selected

            try {
                const res = await this.$http.post(this.endpoint[0] + this.endpoint[1] + '/mass-effect', payload)
                if (res) {
                    if (this.selected.length > 0) {
                        this.selected = []
                    }

                    this.$toast.success(res.data.msg)
                }
            } catch (err) {
                this.$fnError(err)
            }
            
            this.closeMassDelete()
            this.massDeleting = false
            this.load()
        },

        close () {
            this.dialog = false

            this.repopulateFilters()

            if (this.editedIndex > -1) {
                this.returnEditedItemToDefault()
                this.editedIndex = -1
            }
        },

        repopulateFilters() {
            this.options.forEach(async o => {
                if (o.filteredBy) {
                    let foundCondition = this.filterConditions.find(f => o.filteredBy.includes(f.column))

                    if (foundCondition) {
                        let filter = {
                            conditions: [
                                {
                                    AndOr: 'AND',
                                    column: foundCondition.column,
                                    operator: '=',
                                    value: foundCondition.value
                                }
                            ]
                        }
    
                        try {
                            let res = await this.$http.post(o.endpoint[0] + o.endpoint[1] + '/list-options', { filter })
                            if (res) {
                                o.filteredItems = res.data.rows
                            }
                        } catch (err) {
                            this.$fnError(err)
                        }
                    }
                    
                }
            })
        },

        closeDelete () {
            this.dialogDelete = false

            if (this.editedIndex > -1) {
                this.returnEditedItemToDefault()
                this.editedIndex = -1
            }
        },

        closeMassDelete () {
            this.dialogMassDelete = false
        },

        massSave(itemKey) {
            if (this.unsavedChanges) {
                this.backupPage = this.pagination.page

                let payload = {
                    edit: { items: [] },
                }

                this.apiResult.items.forEach(tableItem => {
                    if (this.editedItemsId.includes(tableItem[itemKey])) {
                        payload.edit.items.push(tableItem)
                    }
                })

                this.$http.post(this.endpoint[0] + this.endpoint[1] + '/mass-effect', payload)
                .then(res => {
                    this.$toast.success(res.data.msg)
                    this.load()
                })
                .catch((err)=>{
                    this.$fnError(err)
                })
            }
        },

        async save () {
            this.saving = true
            this.selected = []
            let object = {}
            let id = null
            this.backupPage = this.pagination.page

            this.editedItem.forEach(item => {
                if (item.label === 'ID') {
                    id = item.value
                }
                object[item.name] = item.value
            })

            if (this.endpoint[2]) {
                if (this.endpoint[2].conditions.length) {
                    this.endpoint[2].conditions.forEach(condition => {
                        object[condition.column] = condition.value
                    })
                }
            }

            if (this.editedIndex <= 0) {
                try {
                    const res = await this.$http.post(this.endpoint[0] + this.endpoint[1] + '/create', { ...object })
                    if (res) {
                        this.$toast.success(res.data.msg)
                    }
                } catch (err) {
                    this.$fnError(err)
                }
                
            } else {
                try {
                    const res = await this.$http.put(this.endpoint[0] + this.endpoint[1] + `/edit/${id}`, { ...object })
                    if (res) {
                        this.$toast.success(res.data.msg)
                    }
                } catch (err) {
                    this.$fnError(err)
                }
            }

            this.close()
            this.saving = false
            this.load()
        },

        exportSheet(){
            this.exporting = true

            let payload = this.setPayload({}, this.endpoint[2])

            this.$http.post(this.endpoint[0] + this.endpoint[1] + '/report', { ...payload }) 
            .then((res) => {
                window.open(res.data.token)
                this.$toast.success(res.data.msg)
                this.exporting = false
            })
            .catch((err)=>{
                this.exporting = false
                this.$fnError(err)
            })
        },

        importSheet(){
            this.$refs.uploader.click()
        },

        onFileChanged(e) {
            if (e) {
                this.importing = true
                let sheet = e.target.files[0]
                let body = new FormData()
                body.append('spreadsheet', sheet)
    
                this.$http.post(this.endpoint[0] + this.endpoint[1] + '/upload-spreadsheet',
                    body, { headers: { 'Content-Type': 'multipart/form-data' }}
                )
                .then((res)=>{
                    this.$http.post(this.endpoint[0] + this.endpoint[1] + '/mass-effect', {...res.data.id})
                    .then((res)=>{
                        this.$toast.success(res.data.msg)
                        this.$refs.uploader.value = null
                        this.importing = false
                        this.load()
                    }).catch((err)=>{
                        this.$refs.uploader.value = null
                        this.importing = false
                        this.$fnError(err)
                    })
                })
                .catch((err)=>{
                    this.$refs.uploader.value = null
                    this.importing = false
                    this.$fnError(err)
                })
            }
        },
    },
}
</script>

<style>
.edited-row {
    background-color: #fdebb9b6;
}

.edited-row:hover {
    background-color: #fad777b6 !important;
}

.selected-row {
    background-color: #eee !important;
}

.selected-row:hover {
    background-color: #ddd !important;
}

.v-small-dialog__activator__content {
    width: 100%;
}

.v-data-footer {
    margin-right: 0 !important; 
}
</style>
